import { AuthenticationLayout as Layout } from 'presentation/components/AuthenticationLayout'
import React from 'react'
import { useTranslation } from 'react-i18next'
export const AuthorizeConfirmed = () => {
  const { t } = useTranslation()
  return (
    <Layout>
      <p className="text-xl px-12">{t('ご本人確認ありがとうございました')}</p>
      <div className="w-full px-6 pt-4 pb-8">
        <p className="font-bold underline">
          {t('本人確認が完了しましたので元のログイン画面をご確認ください。')}
        </p>
      </div>
    </Layout>
  )
}
